import {useEffect, useState} from "react";
import React from "react";
import WelcomeComponent from "../components/welcome/WelcomeComponent";
import {PageType} from "../utils/PageType";
import EmailComponent from "../components/email/EmailComponent";
import {UserInfo} from "../dto/UserInfo";
import RedeemComponent from "../components/redeem/RedeemComponent";
import {useLocation, useNavigate} from "react-router-dom";
import {CampaignService} from "../services/CampaignService";
import Lottie from "lottie-react";
import lottieLoading from "../animations/lottie_loading.json";
import lottieVerified from "../animations/lottie_verified.json";
import {AnimatePresence, motion } from "framer-motion";


const AppPage: React.FC = () => {

    const [pageType, setPageType] = useState<PageType>(PageType.WELCOME);
    const [userInfo, setUserInfo] = useState<UserInfo>({
        phone: "",
        email: ""
    })

    const [verified, setVerified] = useState<boolean>(false);
    const [loading, setIsLoading] = useState<boolean>(true);

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const checkCode = async () => {
            const queryParams = new URLSearchParams(location.search);
            const code = queryParams.get('code');

            console.log(code);
            if (!code) {
                navigate('/not-found');
                return;
            }

            try {
                const response = await CampaignService.verifyCampaignCode(code);

                if (response.used) {
                    navigate('/redeemed');
                } else {
                    setUserInfo({
                        ...userInfo,
                        code: response
                    })
                }
            } catch (error) {
                console.log(error);
                navigate('/not-found');
            } finally {
                setTimeout(() => {
                    setVerified(true);
                }, 3000)
                setTimeout(() => {
                    setIsLoading(false);
                }, 6000)
            }
        };

        checkCode();
    }, [location, navigate]);

    return (
        <>
            {loading ?
                <div className="h-screen w-full flex flex-col items-center">
                    <div className={"flex flex-row items-center gap-8 mt-[10%]"}>
                        <img src={"./assets/images/logo-orange.svg"} alt={"simly-logo"} className={"w-28"}/>
                    </div>

                    <div className="flex h-full flex-col items-center justify-center text-center">
                        <AnimatePresence mode={"wait"}>
                            <motion.div
                                className={"flex flex-col"}
                                key={verified ? "validated" : "validating"}
                                initial={{ opacity: 1, x: -1000 }}
                                animate={{ opacity: 1, x: 0 }}
                                exit={{ opacity: 1, x: 1000 }}
                                transition={{ duration: 0.5 }}
                            >
                                <p className={"text-3xl font-gilroy-bold mb-5"}>{verified ? "Validated Code!" : "Validating Code..."}</p>
                                <Lottie className={"w-64 h-64"} animationData={verified ? lottieVerified : lottieLoading} loop={true}/>
                            </motion.div>
                        </AnimatePresence>
                    </div>
                </div>
                :
                pageType === PageType.WELCOME ? <WelcomeComponent setPageType={setPageType}/> :
                    pageType === PageType.EMAIL ?
                        <EmailComponent setPageType={setPageType} userInfo={userInfo} setUserInfo={setUserInfo}/> :
                        pageType === PageType.REDEEMED ?
                            <RedeemComponent setPageType={setPageType} userInfo={userInfo} setUserInfo={setUserInfo}/>
                            : <></>
            }
        </>
    );
}

export default AppPage;
